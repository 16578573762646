import axios, { AxiosHeaders, AxiosResponse, Method } from "axios";

const axiosInstance = axios.create();
axiosInstance.interceptors.request.use((request) => {
  const token = document.cookie
    .split("; ")
    .find((row) => row.startsWith("authToken="))
    ?.split("=")[1];

  if (token) {
    request.headers.Authorization = `Bearer ${token}`;
    if (window.location.hostname === "localhost") {
      document.cookie = `authToken=${token}; path=/`;
    }
    if (window.location.hostname.includes("rstv.app")) {
      document.cookie = `authToken=${token}; domain=.rstv.app; path=/`;
    }
  }

  return request;
});
export async function apiRequest<D = {}, R = unknown>(
  method: Method,
  path: string,
  data?: D,
  headers?: AxiosHeaders,
  params?: any
) {
  let apiBaseUrl: string;

  if (window.location.hostname === "dev.apps.rstv.app" || window.location.hostname === "localhost") {
    apiBaseUrl = process.env.REACT_APP_API || "";
  } else {
    apiBaseUrl = "https://api.rstv.app";
  }

  const url = `${apiBaseUrl}/${path}`;
  const response = await axiosInstance.request<D, AxiosResponse<R>>({
    url,
    method,
    data,
    headers,
    params,
  });

  return response.data;
}
