// import classNames from "classnames";
import { FunctionComponent } from "react";

export interface ILoadingSpinner {
  classNameBody?: string;
  classNameGif?: string;
  text?: string;
}

const LoadingSpinner: FunctionComponent<ILoadingSpinner> = ({
  classNameBody,
  classNameGif,
  text,
}) => {
  return (
    <div className={`p-[10px] rounded-[25px] bg-main-0 ${classNameBody}`}>
      <img
        className={`h-[64px] w-[64px] ${classNameGif}`}
        src="/Flamingo_Loading_64.gif"
        alt="gif"
      />
      {text && <p className="text-center">{text}</p>}
    </div>
  );
};

export default LoadingSpinner;
