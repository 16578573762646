import LoadingSpinner from "./LoadingSpinner";
import classNames from "classnames";
import { Fragment, FunctionComponent, ReactNode } from "react";

export interface ILoadingBoundary {
  loading: boolean;
  children: ReactNode;
  message?: string;
  className?: string;
}

const LoadingBoundary: FunctionComponent<ILoadingBoundary> = ({
  loading,
  children,
  message,
  className,
}) => {
  const loadingBoundaryClassNames = classNames(
    {
      "flex flex-col items-center justify-center w-full py-12 text-main-100 dark:text-main-20 bg-main-100":
        true,
    },
    className
  );

  if (loading)
    return (
      <div className={loadingBoundaryClassNames}>
        <LoadingSpinner />
        {message || "Loading data..."}
      </div>
    );

  return <Fragment>{children}</Fragment>;
};

export default LoadingBoundary;
