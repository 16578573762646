import { UseQueryOptions, useQuery } from "@tanstack/react-query";
import {
  IConfigInstanceIdRequest,
  IConfigInstanceIdResponse,
  getConfigInstanceId,
} from "../api/appInstance";
import { appKeys } from "../keys/appKeys";

export function useGetConfigInstanceId(
  params: IConfigInstanceIdRequest,
  options?: UseQueryOptions<IConfigInstanceIdResponse, IConfigInstanceIdRequest>
) {
  return useQuery<IConfigInstanceIdResponse, IConfigInstanceIdRequest>(
    appKeys.app(params.instanceId),
    () =>
      getConfigInstanceId({
        instanceId: params.instanceId,
      }),
    options
  );
}
