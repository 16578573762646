import {
  UseMutationOptions,
  useMutation,
  useQueryClient,
} from "@tanstack/react-query";
import {
  IAppDefaultConfigIdRequest,
  IGetAllInstanceWithId,
  IUpdateInstanceDuration,
  IUpdateInstanceDurationResponse,
  getDefaultConfig,
  updateDuration,
} from "../api/appInstance";

export const useGetDefaultConfigs = (
  options?: UseMutationOptions<
    IGetAllInstanceWithId,
    any,
    IAppDefaultConfigIdRequest,
    unknown
  >
) => {
  const queryClient = useQueryClient();

  return useMutation(getDefaultConfig, {
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries(["defaultConfigs", variables.instanceId]);
      if (options?.onSuccess) {
        options.onSuccess(data, variables, context);
      }
    },
    onError: (error) => {},
    ...options,
  });
};

export const useUpdateDuration = (
  options?: UseMutationOptions<
    IUpdateInstanceDurationResponse,
    any,
    IUpdateInstanceDuration,
    unknown
  >
) => {
  const queryClient = useQueryClient();

  return useMutation(updateDuration, {
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries(["defaultConfigs", variables.instanceId]);
      if (options?.onSuccess) {
        options.onSuccess(data, variables, context);
      }
    },
    onError: (error) => {},
    ...options,
  });
};
