import { apiRequest } from "./apiRequest";

export interface AppConfigs {
  theme: string;
  domain: string;
  streams: number;
  filter_by: string[];
  show_logo: boolean;
  date_format: string[];
  show_author: boolean;
  show_qr_code: boolean;
  layout: string;
  items: number;
  location: string;
  default_image: string;
  slide_duration: number;
  available_calendars: {
    id: number;
    name: string;
  }[];
  temperature_unit: string;
  ticker: boolean;
  number_of_posts: number;
  theme_available: string[];
  show_stream_name: boolean;
  available_streams: {
    id: number;
    name: string;
  }[];
  date_format_available: string[];
}
export interface AppDetails {
  id: number;
  image: string;
  requiresAuthentication: boolean;
  key: string;
  name: string;
}
export interface IConfigInstanceIdRequest {
  instanceId: string;
}
export interface IConfigInstanceIdResponse {
  id: string;
  name: string;
  configs: AppConfigs;
  app: AppDetails;
  success: boolean;
  data: any;
}
export interface IAppDefaultConfigIdRequest {
  [x: string]: unknown;
  appKey: string;
  configs: any;
}
export interface IGetAllInstanceWithId {
  result: any;
  id: string;
  name: string;
  configs: AppConfigs;
  app: AppDetails;
  fonts: any;
  data: any;
  themes: any;
}

export interface IUpdateInstanceDuration {
  instanceId: string;
  duration: number;
}
export interface IUpdateInstanceDurationResponse {}

export const getConfigInstanceId = ({
  instanceId,
}: IConfigInstanceIdRequest) => {
  return apiRequest<IConfigInstanceIdRequest, IConfigInstanceIdResponse>(
    "GET",
    `apps/instance/${instanceId}`
  );
};

export const getDefaultConfig = ({
  appKey,
  configs,
}: IAppDefaultConfigIdRequest) => {
  return apiRequest<IAppDefaultConfigIdRequest, IGetAllInstanceWithId>(
    "POST",
    `apps/preview/${appKey}`,
    configs
  );
};

export const updateDuration = ({
  duration,
  instanceId,
}: IUpdateInstanceDuration) =>
  apiRequest<any, IUpdateInstanceDurationResponse>(
    "PATCH",
    `apps/instance/${instanceId}/duration`,
    { duration }
  );
